import { useEffect, useState, useMemo, ReactElement } from 'react';

import { outlinedInputClasses, TextFieldProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';

import DateRangePicker, { DateRangeValues, NullableDate } from '../../components/DateRangePicker';
import { getErrorMessage } from '../../lib/error';
import { ListPayments } from '../../lib/useDeveloperApi';
import { Payment } from './Components/PaymentDetails';
import { PaymentsTable } from './PaymentsTable';

interface Filter {
  offset: number;
  mandateId: string;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

// getNumbericVal will return the numeric value if valid number string else will return the default value passed
const getNumericVal = (val: string | null, defaultVal: number) => {
  if (val === null || isNaN(parseInt(val))) return defaultVal;
  return parseInt(val);
};

const statuses = ['AUTHORIZATION_REQUIRED', 'AUTHORIZING', 'PROCESSING', 'SUBMITTED', 'EXECUTED', 'FAILED', 'REVOKED'];
const paymentTypeOptions = [
  { value: 'MANUAL', label: 'Bank transfer (manual)' },
  { value: 'CARD', label: 'Card' },
  { value: 'MANDATE', label: 'Direct debit' },
  { value: 'SINGLE', label: 'Single' },
];

const getStyles = (name: string, statuses: string[], theme: Theme) => {
  return {
    fontWeight: statuses.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

const getPre = (query: string) => (query === '' ? '?' : '&');

const getParams = (
  values: Filter,
  dateRangeValue: DateRangeValues,
  status: string[],
  currencies: string[],
  paymentTypes: string[],
): string => {
  let params = '';
  if (dateRangeValue[0] !== null) {
    params += `${getPre(params)}date_from=${dateRangeValue[0].toISOString().slice(0, 10)}`;
  }
  if (dateRangeValue[1] !== null) {
    params += `${getPre(params)}date_to=${dateRangeValue[1].toISOString().slice(0, 10)}`;
  }
  if (status.length > 0) {
    params += `${getPre(params)}status=${status.join(',')}`;
  }
  if (currencies.length > 0) {
    params += `${getPre(params)}currencies=${currencies.join(',')}`;
  }
  if (values.mandateId !== '') {
    params += `${getPre(params)}mandate_id=${values.mandateId}`;
  }
  if (paymentTypes.length > 0) {
    params += `${getPre(params)}payment_types=${paymentTypes.join(',')}`;
  }
  return params;
};

export const ViewPayments = ({ customerAppId }: { customerAppId: string }): ReactElement => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const history = useHistory();
  // change the following constant to change the page size
  const pageSize = 100;

  let initialStatus: string[] = [];
  const query = useQuery();
  const statusQuery = query.get('status');
  if (statusQuery !== null) {
    initialStatus = statusQuery.split(',');
  }

  let initialCurrencies: string[] = [];
  const currenciesQuery = query.get('currencies');
  if (currenciesQuery !== null) {
    initialCurrencies = currenciesQuery.split(',');
  }

  let initialPaymentTypes: string[] = [];
  const paymentTypesQuery = query.get('payment_types');
  if (paymentTypesQuery !== null) {
    initialPaymentTypes = paymentTypesQuery.split(',');
  }

  // Date range state
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValues>([null, null]);

  const [status, setStatus] = useState<string[]>(initialStatus);
  const [currencies, setCurrencies] = useState<string[]>(initialCurrencies);
  const [paymentTypes, setPaymentTypes] = useState<string[]>(initialPaymentTypes);
  const { data, error } = ListPayments(customerAppId, {
    statuses: initialStatus,
    from: query.get('date_from') ?? undefined,
    to: query.get('date_to') ?? undefined,
    limit: pageSize,
    offset: getNumericVal(query.get('offset'), 0),
    currencies: initialCurrencies,
    paymentTypes: initialPaymentTypes,
    mandateId: query.get('mandate_id') ?? '',
  });

  const [values, setValues] = useState<Filter>({
    offset: getNumericVal(query.get('offset'), 0),
    mandateId: query.get('mandate_id') ?? '',
  });

  // for handling changes to data
  useEffect(() => {
    setPayments(data?.payments ?? []);
    setTotal(data?.total_payments ?? null);
    if (data) {
      enqueueSnackbar('Loaded payments', { variant: 'info', autoHideDuration: 1000 });
    }
  }, [data, enqueueSnackbar]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  // update the view when query params changes
  useEffect(() => {
    const dateFrom = query.get('date_from');
    const dateTo = query.get('date_to');
    if (dateFrom !== null || dateTo !== null) {
      setDateRangeValue((prevState) => {
        const df = dateFrom !== null ? new Date(dateFrom) : prevState[0];
        const dt = dateTo !== null ? new Date(dateTo) : prevState[1];
        return [df, dt];
      });
    }

    setValues({
      offset: getNumericVal(query.get('offset'), 0),
      mandateId: query.get('mandate_id') ?? '',
    });
  }, [query]);

  const handleChange = (prop: keyof Filter) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleStatusChange = (event: SelectChangeEvent<typeof statuses>) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value);
  };

  const goPrev = () => {
    let params = getParams(values, dateRangeValue, status, currencies, paymentTypes);
    params += `${getPre(params)}offset=${values.offset - pageSize}`;

    history.push({
      search: params,
    });
  };

  const goNext = () => {
    let params = getParams(values, dateRangeValue, status, currencies, paymentTypes);
    params += `${getPre(params)}offset=${values.offset + pageSize}`;

    history.push({
      search: params,
    });
  };

  const loadView = () => {
    history.push({
      search: getParams(values, dateRangeValue, status, currencies, paymentTypes),
    });
  };

  // this function will set the current date time so the UTC value for date string will be the same as the locale time
  // we do this by adding the UTC offset to the date (local), eg 8am in HKT -> 12am in UTC same date.
  // by default the DatePicker component returns the date time as midnight which when converted to UTC will return yesterday's date
  const setDateWithOffset = (newValue: Date) => {
    newValue.getTimezoneOffset(); // get the UTC offset
    newValue.setHours(0); // set the hour to 0 so adding offset will always be the same
    newValue.setMinutes(-newValue.getTimezoneOffset()); // add the offset so we get the local time with UTC offset
  };

  const searchBar = () => {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={2}>
        <DateRangePicker
          values={dateRangeValue}
          views={['day']}
          inputFormat="yyyy-MM-dd"
          onStartDateChange={(newValue: NullableDate) => {
            if (newValue !== null) {
              setDateWithOffset(newValue);
            }
            setDateRangeValue([newValue, dateRangeValue[1]]);
          }}
          onEndDateChange={(newValue: NullableDate) => {
            if (newValue !== null) {
              setDateWithOffset(newValue);
            }
            setDateRangeValue([dateRangeValue[0], newValue]);
          }}
          renderInput={(params: TextFieldProps) => (
            <Box sx={{ width: 200 }}>
              <TextField
                fullWidth
                {...params}
                sx={{
                  [`& .${outlinedInputClasses.root}:hover > fieldset`]: { borderColor: 'primary.dark' },
                }}
              />
            </Box>
          )}
        />
        <FormControl fullWidth>
          <InputLabel id="select-status-label">Status</InputLabel>
          <Select
            labelId="select-status-label"
            id="select-status"
            multiple
            value={status}
            fullWidth
            onChange={handleStatusChange}
            renderValue={(selected) => selected.join(', ')}
            label="Status"
          >
            {statuses.map((s) => (
              <MenuItem sx={{ fontSize: '0.8em' }} key={s} value={s} style={getStyles(s, statuses, theme)}>
                <Checkbox checked={status.includes(s)} />
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="currency-select-label">Currency</InputLabel>
          <Select
            labelId="currency-select-label"
            id="currency-select"
            multiple
            value={currencies}
            label="Currency"
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              setCurrencies(typeof value === 'string' ? value.split(',') : value);
            }}
            renderValue={(selected) => selected.join(', ')}
          >
            {['CNY', 'HKD', 'SGD'].map((ccy) => (
              <MenuItem sx={{ fontSize: '0.8em' }} key={ccy} value={ccy}>
                <Checkbox checked={currencies.includes(ccy)} />
                {ccy}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="payment-type-select-label">Payment Type</InputLabel>
          <Select
            labelId="payment-type-select-label"
            id="payment-type-select"
            multiple
            value={paymentTypes}
            label="Payment Type"
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              setPaymentTypes(typeof value === 'string' ? value.split(',') : value);
            }}
            renderValue={(selected) => selected.join(', ')}
          >
            {paymentTypeOptions.map((pt) => (
              <MenuItem value={pt.value} sx={{ fontSize: '0.8em' }} key={pt.value}>
                <Checkbox checked={paymentTypes.includes(pt.value)} />
                {pt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="mandate-id"
          label="Mandate ID"
          variant="outlined"
          value={values.mandateId}
          onChange={handleChange('mandateId')}
          fullWidth
        />
        <Button fullWidth variant="contained" onClick={loadView}>
          View
        </Button>
      </Stack>
    );
  };

  const titleBar = () => (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        marginBottom: '1em',
        marginTop: '0.5em',
      }}
    >
      <Grid item>
        <Typography variant="h4" component="h4">
          Payments
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">
          Showing {Math.min(total as number, values.offset + 1)} - {Math.min(values.offset + pageSize, total as number)}{' '}
          of {total}
        </Typography>
      </Grid>
    </Grid>
  );

  const pagination = () => (
    <ButtonGroup
      disableElevation
      size="small"
      aria-label="Pagination"
      variant="contained"
      sx={{ width: '250px', marginTop: '1em' }}
    >
      <Button fullWidth disabled={values.offset === 0} onClick={goPrev}>
        Previous
      </Button>
      <Button
        fullWidth
        disabled={Math.min(values.offset + pageSize, total as number) >= Number(total)}
        onClick={goNext}
      >
        Next
      </Button>
    </ButtonGroup>
  );

  return (
    <>
      {searchBar()}
      {titleBar()}
      <PaymentsTable customerAppId={customerAppId} items={payments} />
      {pagination()}
    </>
  );
};
