import { DialogContent, Table, TableBody, TableRow, TableCell, DialogTitle } from '@mui/material';

import { EmbeddedFvError } from '../../../lib/interface';
import { getCurrencyView } from '../../../utils/format';
import { Recipient, Sender, SenderAccount } from '../MandatesTable';
import RowIfNotEmpty from './Row';
import DialogWrapper from './Wrapper';

type Props = {
  open: boolean;
  handleClose: () => void;
  payment: Payment;
  customerAppId: string;
};

type Fee = {
  amount: number;
  currency: string;
  paid_by: 'SENDER' | 'RECIPIENT';
  paid_by_account_id: string;
};

export type Payment = {
  payment_id: string;
  amount: number;
  amount_total_with_surcharge: number;
  surcharge_amount: number;
  currency: string;
  type: string;
  updated_at: string;
  status: string;
  payment_details: {
    description: string;
    mandate_id: string;
    external_transaction_reference: string;
    references?: {
      finverse_transaction_reference: string;
      dda_reference?: string;
    };
  };
  recipient: Recipient;
  sender: Sender;
  sender_account: SenderAccount;
  created_at: string;
  payment_method_id?: string;
  payment_method?: {
    card: {
      card_details: {
        brand: string;
        last4: string;
      };
    };
  };
  metadata?: Record<string, string>;
  fees: Fee[];
  error?: EmbeddedFvError;
};

export function PaymentDetails({ open, handleClose, payment, customerAppId }: Props) {
  return (
    <DialogWrapper
      id=""
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      title="Payment Details"
      open={open}
    >
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell align="right">{getCurrencyView(payment.amount, payment.currency)}</TableCell>
            </TableRow>
            {RowIfNotEmpty('Surcharge Amount', getCurrencyView(payment.surcharge_amount, payment.currency))}
            <TableRow>
              <TableCell>Total Amount with Surcharge</TableCell>
              <TableCell align="right">
                {getCurrencyView(payment.amount_total_with_surcharge, payment.currency)}
              </TableCell>
            </TableRow>
            {renderFees(payment)}
            {RowIfNotEmpty('Customer App ID', customerAppId)}
            <TableRow>
              <TableCell>Recipient Name</TableCell>
              <TableCell align="right">{payment.recipient.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recipient Account ID</TableCell>
              <TableCell align="right">{payment.recipient.recipient_account_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">{payment.payment_details.description}</TableCell>
            </TableRow>
            {RowIfNotEmpty('DDA Reference', payment.payment_details.references?.dda_reference)}
            {RowIfNotEmpty('Payment Method ID', payment.payment_method_id)}
            {RowIfNotEmpty('Mandate ID', payment.payment_details.mandate_id)}
            {RowIfNotEmpty('Card brand', payment.payment_method?.card.card_details.brand)}
            {RowIfNotEmpty('Card number (last 4)', payment.payment_method?.card.card_details.last4)}
            <TableRow>
              <TableCell>External Transaction Reference</TableCell>
              <TableCell align="right">{payment.payment_details.external_transaction_reference}</TableCell>
            </TableRow>
            {RowIfNotEmpty(
              'Finverse Transaction Reference',
              payment.payment_details.references?.finverse_transaction_reference,
            )}
            <TableRow>
              <TableCell>Sender Name</TableCell>
              <TableCell align="right">{payment.sender.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Finverse User ID</TableCell>
              <TableCell align="right">{payment.sender.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>External User ID</TableCell>
              <TableCell align="right">{payment.sender.external_user_id}</TableCell>
            </TableRow>
            {RowIfNotEmpty('Sender Institution', payment.sender.sender_account?.institution_id)}
            <TableRow>
              <TableCell>Sender Type</TableCell>
              <TableCell align="right">{payment.sender.sender_type}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {renderMetadata(payment.metadata)}
      </DialogContent>
    </DialogWrapper>
  );
}

const renderMetadata = (metadata?: Record<string, string>): JSX.Element => {
  if (metadata === undefined) {
    return <></>;
  }

  const rows = [];

  for (const key of Object.keys(metadata)) {
    rows.push(RowIfNotEmpty(key, metadata[key]));
  }

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center' }}>Metadata</DialogTitle>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  );
};

const renderFees = (payment: Payment): JSX.Element => {
  const feeRowsRecipient = [];
  const feeRowsSender = [];

  for (const fee of payment.fees) {
    if (fee.paid_by === 'SENDER') {
      feeRowsSender.push(
        RowIfNotEmpty('Fee Surcharge (paid by sender)', `${fee.currency} ${getCurrencyView(fee.amount)}`),
      );
    } else {
      feeRowsRecipient.push(RowIfNotEmpty('Payment Fee', `${fee.currency} ${getCurrencyView(fee.amount)}`));
    }
  }

  return <>{feeRowsRecipient.concat(feeRowsSender)}</>;
};
