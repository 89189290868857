/**
 * @input string utc date
 */
export const utcToString = (date: string) => date.replace(/T/, ' ').replace(/\..+/, '');

export const getLocalTimezoneCode = (): string =>
  new Date().toLocaleDateString(undefined, { timeZoneName: 'short' }).split(' ')[1];

/**
 * Converts string from ISO-8601 UTC to human-readable format in device local timezone
 * i.e. YYYY-MM-DD HH:mm
 * @param utcDateString
 */
export const utcDateTimeToLocal = (utcDateString: string): string => {
  const dateObject = new Date(utcDateString);
  // js doesn't offer a good way to display date in ISO format in local timezone
  // Idea: Create a new date object where time in UTC = desired local time and display that in ISO format and work from there
  const offsetDate = new Date(dateObject.getTime() - dateObject.getTimezoneOffset() * 60 * 1000);
  return offsetDate
    .toISOString()
    .substring(0, 16) // drop anything after minutes (i.e. seconds, milliseconds, timezone)
    .replace('T', ' '); // remove the T separating date and time
};

export const getCurrencyView = (amount?: number, currency?: string): string => {
  if (amount === undefined) {
    return '';
  }
  const dollar = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2, // this means we will always show 2 decimal places even if .00
  });
  // the currency amount is stored in minor format (x100), so we need to divide by 100 and format
  const formattedAmount = dollar.format(Math.round(amount) / 100);
  return currency === undefined ? formattedAmount : `${currency} ${formattedAmount}`;
};
