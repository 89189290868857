import { Fragment, useState, ReactElement } from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { getCurrencyView, getLocalTimezoneCode, utcDateTimeToLocal } from '../../utils/format';
import { ErrorDetails } from './Components/ErrorDetails';
import { Payment, PaymentDetails } from './Components/PaymentDetails';
import { Dialog } from './MandatesTable';

export const PaymentsTable = ({ customerAppId, items }: { customerAppId: string; items: Payment[] }): ReactElement => {
  const localTimezoneCode = getLocalTimezoneCode();
  const [open, setOpen] = useState<string>('');
  const [dialog, setDialog] = useState<Dialog>(Dialog.Any);
  const handleClose = () => {
    handleMenuClose();
  };

  const setView = (paymentId: string, dialog: Dialog) => {
    setOpen(paymentId);
    setDialog(dialog);
  };

  const handleMenuClose = () => {
    setOpen('');
    setDialog(Dialog.Any);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="payments table">
        <TableHead>
          <TableRow sx={{ '& th': { fontWeight: 600 } }}>
            <TableCell align="center">Updated At ({localTimezoneCode})</TableCell>
            <TableCell align="center">Payment ID</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Currency</TableCell>
            <TableCell align="center">Reference</TableCell>
            <TableCell align="center">Payer</TableCell>
            <TableCell align="center">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: Payment) => (
            <Fragment key={item.payment_id}>
              <PaymentDetails
                payment={item}
                open={item.payment_id === open && dialog === Dialog.Details}
                handleClose={handleClose}
                customerAppId={customerAppId}
              />
              {item.error && (
                <ErrorDetails
                  error={item.error}
                  open={item.payment_id === open && dialog === Dialog.Error}
                  handleClose={handleClose}
                />
              )}
              <TableRow key={item.payment_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center">{utcDateTimeToLocal(item.updated_at)}</TableCell>
                <TableCell align="center">{item.payment_id}</TableCell>
                <TableCell align="center">
                  {item.status !== 'FAILED' ? (
                    item.status
                  ) : (
                    <Button onClick={() => setView(item.payment_id, Dialog.Error)} sx={{ padding: 0 }} size="large">
                      {item.status}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center">{item.type}</TableCell>
                <TableCell align="center">{getCurrencyView(item.amount)}</TableCell>
                <TableCell align="center">{item.currency}</TableCell>
                <TableCell align="center">{item.payment_details.external_transaction_reference}</TableCell>
                <TableCell align="center">{item.sender.name}</TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      setView(item.payment_id, Dialog.Details);
                    }}
                  >
                    {item.payment_details.description}
                  </Button>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
