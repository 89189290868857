import { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
  outlinedInputClasses,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import DateRangePicker, { DateRangeValues } from '../../components/DateRangePicker';
import useQuery from '../../hooks/useQuery';
import { getErrorMessage } from '../../lib/error';
import { PaymentMethodWithJoinedData } from '../../lib/interface';
import { paymentMethodStatuses } from '../../lib/status';
import { ListPaymentMethods } from '../../lib/useDeveloperApi';
import { getNumericVal } from '../../utils/number';
import { PaymentMethodsTable } from './PaymentMethodsTable';

export const ViewPaymentMethods = ({ customerAppId }: { customerAppId: string }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodWithJoinedData[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const pageSize = 100;
  const query = useQuery();

  // form query parameters
  const [offset, setOffset] = useState(getNumericVal(query.get('offset'), 0));
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValues>([null, null]);
  const [searchText, setSearchText] = useState('');
  const [paymentMethodType, setPaymentMethodType] = useState('');
  const [autopayConsent, setAutopayConsent] = useState('');
  const [pmStatus, setPmStatus] = useState<string[]>([]);

  const { data, error } = ListPaymentMethods(customerAppId, {
    offset: getNumericVal(query.get('offset'), 0),
    limit: pageSize,
    date_from: query.get('date_from') ?? undefined,
    date_to: query.get('date_to') ?? undefined,
    statuses: query.get('statuses') ?? undefined,
    payment_method_type: query.get('payment_method_type') ?? undefined,
    search_text: query.get('search_text') ?? undefined,
    autopay_consent: query.get('autopay_consent') ?? undefined,
  });

  useEffect(() => {
    const dateFrom = query.get('date_from');
    const dateTo = query.get('date_to');
    if (dateFrom !== null || dateTo !== null) {
      setDateRangeValue((prevState) => {
        const df = dateFrom !== null ? new Date(dateFrom) : prevState[0];
        const dt = dateTo !== null ? new Date(dateTo) : prevState[1];
        return [df, dt];
      });
    }

    const searchText = query.get('search_text');
    if (searchText !== null) {
      setSearchText(searchText);
    }

    const paymentMethodType = query.get('payment_method_type');
    if (paymentMethodType !== null) {
      setPaymentMethodType(paymentMethodType);
    }

    const autopayConsent = query.get('autopay_consent');
    if (autopayConsent !== null) {
      setAutopayConsent(autopayConsent === 'true' || autopayConsent === 'false' ? autopayConsent : '');
    }

    const statuses = query.get('statuses');
    if (statuses != null) {
      setPmStatus(statuses.split(','));
    }

    const offset = query.get('offset');
    if (offset !== null) {
      setOffset(getNumericVal(offset, 0));
    }
  }, [query]);

  useEffect(() => {
    setPaymentMethods(data?.payment_methods ?? []);
    setTotal(data?.total_payment_methods ?? null);
    if (data) {
      enqueueSnackbar('Loaded payment methods', { variant: 'info', autoHideDuration: 1000 });
    }
  }, [data, enqueueSnackbar]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  const getDateWithOffset = (date: Date) => {
    const dateWithUtcOffset = new Date(date.getTime());
    dateWithUtcOffset.setHours(0);
    dateWithUtcOffset.setMinutes(-date.getTimezoneOffset());
    return dateWithUtcOffset;
  };

  const ViewButton = () => (
    <Button
      fullWidth
      variant="contained"
      sx={{ height: '100%' }}
      onClick={() => {
        const newQueryParams = new URLSearchParams();

        // not setting offset on purpose; we want to view from the start

        if (dateRangeValue[0] !== null) {
          newQueryParams.set('date_from', dateRangeValue[0].toISOString().split('T')[0]);
        }
        if (dateRangeValue[1] !== null) {
          newQueryParams.set('date_to', dateRangeValue[1].toISOString().split('T')[0]);
        }
        if (searchText !== '') {
          newQueryParams.set('search_text', searchText);
        }
        if (paymentMethodType !== '') {
          newQueryParams.set('payment_method_type', paymentMethodType);
        }
        if (autopayConsent !== '') {
          newQueryParams.set('autopay_consent', autopayConsent.toString());
        }
        if (pmStatus.length > 0) {
          newQueryParams.set('statuses', pmStatus.join(','));
        }

        history.push({ search: newQueryParams.toString() });
      }}
    >
      View
    </Button>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item spacing={1} display="flex" justifyContent="start">
          <Grid item xl={2.5} lg={4} md={5} sm={6} xs={12}>
            <DateRangePicker
              values={dateRangeValue}
              views={['day']}
              inputFormat="yyyy-MM-dd"
              onStartDateChange={(newValue) => {
                if (newValue === null) {
                  setDateRangeValue([null, dateRangeValue[1]]);
                  return;
                }
                setDateRangeValue([getDateWithOffset(newValue), dateRangeValue[1]]);
              }}
              onEndDateChange={(newValue) => {
                if (newValue === null) {
                  setDateRangeValue([dateRangeValue[0], null]);
                  return;
                }
                setDateRangeValue([dateRangeValue[0], getDateWithOffset(newValue)]);
              }}
              renderInput={(params: TextFieldProps) => (
                <Box sx={{ width: 200 }}>
                  <TextField
                    fullWidth
                    {...params}
                    sx={{
                      [`& .${outlinedInputClasses.root}:hover > fieldset`]: { borderColor: 'primary.dark' },
                    }}
                  />
                </Box>
              )}
            />
          </Grid>
          <Grid item xl={2.5} lg={2.5} md={2} sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="payment-method-type-select-label">Payment Method Type</InputLabel>
              <Select
                labelId="payment-method-type-select-label"
                id="payment-method-type-select"
                value={paymentMethodType}
                label="Payment Method Type"
                onChange={(e) => setPaymentMethodType(e.target.value)}
              >
                <MenuItem value="" sx={{ fontSize: '0.8em' }}>
                  <em>Any</em>
                </MenuItem>
                <MenuItem value="MANDATE" sx={{ fontSize: '0.8em' }}>
                  Mandate
                </MenuItem>
                <MenuItem value="CARD" sx={{ fontSize: '0.8em' }}>
                  Card
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xl={2.5} lg={2} md={1.5} sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="payment-method-status-label">Status</InputLabel>
              <Select
                labelId="payment-method-status-label"
                id="payment-method-status-select"
                value={pmStatus}
                multiple
                renderValue={(selected) => selected.join(',')}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setPmStatus(typeof newValue === 'string' ? newValue.split(',') : newValue);
                }}
                label="Status"
              >
                {paymentMethodStatuses.map((s) => (
                  <MenuItem sx={{ fontSize: '0.8em' }} key={s} value={s}>
                    <Checkbox checked={pmStatus.includes(s)} />
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xl={2.5} lg={1.5} md={1.5} sm={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="autopay-consent-select-label">Autopay Consent</InputLabel>
              <Select
                labelId="autopay-consent-select-label"
                id="autopay-consent-select"
                value={autopayConsent}
                label="Autopay Consent"
                onChange={(e) => setAutopayConsent(e.target.value)}
              >
                <MenuItem value="" sx={{ fontSize: '0.8em' }}>
                  <em>Any</em>
                </MenuItem>
                <MenuItem value="true" sx={{ fontSize: '0.8em' }}>
                  Yes
                </MenuItem>
                <MenuItem value="false" sx={{ fontSize: '0.8em' }}>
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <ViewButton />
          </Grid>
        </Grid>
        <Grid container item spacing={1} display="flex" alignItems="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="search-bar"
              variant="outlined"
              label="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              helperText="Search by External User ID, Finverse User ID, Mandate ID, Payment Method ID, or Sender Name. Exact matches only, case-insensitive."
            />
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
            <ViewButton />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: '1em', marginTop: '0.5em' }}
      >
        <Grid item>
          <Typography variant="h4" component="h4">
            Payment Methods
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            Showing {offset + 1} - {Math.min(offset + pageSize, total as number)} of {total}
          </Typography>
        </Grid>
      </Grid>
      <PaymentMethodsTable items={paymentMethods} />
      <ButtonGroup
        disableElevation
        size="small"
        aria-label="Pagination"
        variant="contained"
        sx={{ width: '250px', marginTop: '1em' }}
      >
        <Button
          fullWidth
          disabled={offset <= 0}
          onClick={() => {
            const newOffset = Math.max(offset - pageSize, 0);
            query.set('offset', newOffset.toString());
            setOffset(newOffset);
            history.push({ search: query.toString() });
          }}
        >
          Previous
        </Button>
        <Button
          fullWidth
          disabled={Math.min(offset + pageSize, total as number) >= Number(total)}
          onClick={() => {
            const newOffset = Math.min(offset + pageSize, Number(total));
            query.set('offset', newOffset.toString());
            setOffset(newOffset);
            history.push({ search: query.toString() });
          }}
        >
          Next
        </Button>
      </ButtonGroup>
    </>
  );
};
