/**
 * Keep SWR based stuffs here
 */

import { useContext, useMemo } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import useSWR, { SWRResponse } from 'swr';

import { AppContext } from '../contexts/AppContext';
import useTeam from '../hooks/useTeam';
import { ListPaymentMethodsResponse } from './interface';

export const GetInstitutions = () => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/institutions`,
    }),
    [token, env],
  );
  const resp = useSWR(config, fetcher, { shouldRetryOnError: false });
  return resp;
};

export const GetCustomerApps = () => {
  const { app } = useContext(AppContext);
  const { team } = useTeam();
  const { env, tokens } = app;
  const token = tokens.get(env) || '';
  const teamId = team.team_id;

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/team/${teamId}/customers`,
    }),
    [token, env, teamId],
  );
  const resp = useSWR(config, fetcher, { shouldRetryOnError: false });
  return resp;
};

const getPre = (query: string) => (query === '' ? '?' : '&');

function getQueryParams(options: MandateQuery): string {
  let query = '';
  if (options.statuses !== undefined && options.statuses.length > 0) {
    query += `${getPre(query)}statuses=${options.statuses.join(',')}`;
  }
  if (options.from !== undefined) {
    query += `${getPre(query)}date_from=${options.from}`;
  }
  if (options.to !== undefined) {
    query += `${getPre(query)}date_to=${options.to}`;
  }
  if (options.senderType !== undefined) {
    query += `${getPre(query)}sender_type=${options.senderType}`;
  }
  if (options.userId !== undefined) {
    query += `${getPre(query)}user_id=${options.userId}`;
  }
  if (options.institutionId !== undefined) {
    query += `${getPre(query)}institution_id=${options.institutionId}`;
  }
  if (options.limit !== undefined) {
    query += `${getPre(query)}limit=${options.limit}`;
  }
  if (options.offset !== undefined) {
    query += `${getPre(query)}offset=${options.offset}`;
  }
  if (options.paymentType !== undefined) {
    query += `${getPre(query)}payment_type=${options.paymentType}`;
  }
  if (options.mandateId !== undefined) {
    query += `${getPre(query)}mandate_id=${options.mandateId}`;
  }
  if (options.currency !== undefined) {
    query += `${getPre(query)}currency=${options.currency}`;
  }
  return query;
}

function getPaymentQueryParams(query: PaymentQuery): string {
  const params = new URLSearchParams();
  if (query.offset !== undefined) {
    params.set('offset', query.offset.toString());
  }
  if (query.limit !== undefined) {
    params.set('limit', query.limit.toString());
  }
  if (query.from !== undefined && query.from !== '') {
    params.set('date_from', query.from);
  }
  if (query.to !== undefined && query.to !== '') {
    params.set('date_to', query.to);
  }
  if (query.statuses !== undefined && query.statuses.length > 0) {
    params.set('statuses', query.statuses.join(','));
  }
  if (query.currencies !== undefined && query.currencies.length > 0) {
    params.set('currencies', query.currencies.join(','));
  }
  if (query.paymentTypes !== undefined && query.paymentTypes.length > 0) {
    params.set('payment_types', query.paymentTypes.join(','));
  }
  if (query.mandateId !== undefined && query.mandateId !== '') {
    params.set('mandate_id', query.mandateId);
  }

  return params.toString();
}

export const getPayoutQueryParams = (options: PayoutQuery): string => {
  let query = '';
  if (options.statuses !== undefined && options.statuses.length > 0) {
    query += `${getPre(query)}statuses=${options.statuses.join(',')}`;
  }
  if (options.from !== undefined) {
    query += `${getPre(query)}date_from=${options.from}`;
  }
  if (options.to !== undefined) {
    query += `${getPre(query)}date_to=${options.to}`;
  }
  if (options.currency !== undefined && options.currency !== '') {
    query += `${getPre(query)}currency=${options.currency}`;
  }

  if (options.limit !== undefined) {
    query += `${getPre(query)}limit=${options.limit}`;
  }
  if (options.offset !== undefined) {
    query += `${getPre(query)}offset=${options.offset}`;
  }

  return query;
};

export type MandateQuery = {
  statuses?: string[];
  from?: string;
  to?: string;
  senderType?: string;
  userId?: string;
  institutionId?: string;
  limit?: number;
  offset?: number;

  // These are only relevant for payments
  // but can use ? postfix to mark them optional anyway
  paymentType?: string;
  mandateId?: string;
  currency?: string;
};

export type PaymentQuery = {
  statuses?: string[];
  from?: string;
  to?: string;
  limit?: number;
  offset?: number;
  paymentTypes?: string[];
  mandateId?: string;
  currencies?: string[];
};

export const ListMandates = (customerAppId: string, queryParams: MandateQuery) => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const queryString = getQueryParams(queryParams);

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/customer/${customerAppId}/v2/mandates${queryString}`,
    }),
    [token, env, customerAppId, queryString],
  );
  const resp = useSWR(config, fetcher, {
    shouldRetryOnError: false,
  });
  return resp;
};

export const ListPayments = (customerAppId: string, queryParams: PaymentQuery) => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const queryString = getPaymentQueryParams(queryParams);

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/customer/${customerAppId}/payments?${queryString}`,
    }),
    [token, env, customerAppId, queryString],
  );
  const resp = useSWR(config, fetcher, {
    shouldRetryOnError: false,
  });
  return resp;
};

export type PayoutQuery = {
  statuses?: string[];
  from?: string;
  to?: string;
  currency?: string;

  limit?: number;
  offset?: number;
};

export const ListPayouts = (customerAppId: string, queryParams: PayoutQuery) => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  const queryString = getPayoutQueryParams(queryParams);

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/customer/${customerAppId}/payouts${queryString}`,
    }),
    [token, env, customerAppId, queryString],
  );
  const resp = useSWR(config, fetcher, {
    shouldRetryOnError: false,
  });
  return resp;
};

export type DownloadBalanceStatementFilters = {
  date_from?: string;
  date_to?: string;
  currencies: string[];
};

export const useDownloadBalanceStatement = () => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';

  return async (customerAppId: string, filters: DownloadBalanceStatementFilters) => {
    const response = await axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/customer/${customerAppId}/ledger/download`,
      params: {
        date_from: filters.date_from,
        date_to: filters.date_to,
        currencies: filters.currencies.join(','),
      },
    });

    const downloadLink = response.data.download_url;

    if (typeof downloadLink === 'string') {
      window.open(downloadLink, '_blank');
    }
  };
};

export type PaymentMethodQuery = {
  offset?: number;
  limit?: number;
  date_from?: string;
  date_to?: string;
  statuses?: string;
  payment_method_type?: string;
  search_text?: string;
  autopay_consent?: string;
};

function getPaymentMethodQuery(query: PaymentMethodQuery): string {
  const params = new URLSearchParams();
  if (query.offset !== undefined) {
    params.set('offset', query.offset.toString());
  }
  if (query.limit !== undefined) {
    params.set('limit', query.limit.toString());
  }
  if (query.date_from !== undefined && query.date_from !== '') {
    params.set('date_from', query.date_from);
  }
  if (query.date_to !== undefined && query.date_to !== '') {
    // we will increment date_to by 1 day since the date_to's time is considered to be 00:00:00; this will ensure that we will get PM's created on the date_to as well
    const dateTo = new Date(new Date(query.date_to).getTime() + 24 * 60 * 60 * 1000);
    params.set('date_to', dateTo.toISOString().split('T')[0]);
  }
  if (query.statuses !== undefined && query.statuses !== '') {
    params.set('statuses', query.statuses);
  }
  if (query.payment_method_type !== undefined && query.payment_method_type !== '') {
    params.set('payment_method_type', query.payment_method_type);
  }
  if (query.search_text !== undefined && query.search_text !== '') {
    params.set('search_text', query.search_text);
  }
  if (query.autopay_consent !== undefined && query.autopay_consent !== '') {
    params.set('autopay_consent', query.autopay_consent);
  }

  return params.toString();
}

export const ListPaymentMethods = (
  customerAppId: string,
  params: PaymentMethodQuery,
): SWRResponse<ListPaymentMethodsResponse, any> => {
  const { app } = useContext(AppContext);
  const { env, tokens } = app;
  const token = tokens.get(env) || '';
  const queryParams = getPaymentMethodQuery(params);

  const fetcher = (config: AxiosRequestConfig) => axios(config).then((res) => res.data);
  const config: AxiosRequestConfig = useMemo(
    () => ({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `https://developer.${env}.finverse.net/customer/${customerAppId}/payment_methods?${queryParams}`,
    }),
    [token, env, customerAppId, queryParams],
  );
  const resp = useSWR(config, fetcher, {
    shouldRetryOnError: false,
  });
  return resp;
};
