import { Fragment, ReactElement, useState } from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { EmbeddedFvError, PaymentMethod, PaymentMethodWithJoinedData } from '../../lib/interface';
import { getCurrencyView } from '../../utils/format';
import { MandateDetails, CardDetails } from './Components/Details';
import { ErrorDetails } from './Components/ErrorDetails';
import SenderDetailsModal from './Components/Sender';

const getPaymentMethodStatus = (pm: PaymentMethod): string => {
  if (pm.payment_method_type === 'MANDATE') {
    return pm.mandate.status;
  }

  if (pm.payment_method_type === 'CARD') {
    return pm.card.status;
  }

  return 'UNKNOWN';
};

const getPaymentMethodError = (pm: PaymentMethod): EmbeddedFvError | undefined => {
  if (pm.payment_method_type === 'MANDATE') {
    return pm.mandate.error;
  }

  if (pm.payment_method_type === 'CARD') {
    return pm.card.error;
  }

  return undefined;
};

enum Dialog {
  None = '',
  Sender = 'SENDER',
  Details = 'DETAILS',
  Error = 'ERROR',
}

export const PaymentMethodsTable = ({ items }: { items: PaymentMethodWithJoinedData[] }): ReactElement => {
  const [openModalId, setOpenModalId] = useState('');
  const [modalType, setModalType] = useState(Dialog.None);

  const openModal = (paymentMethodId: string, modalType: Dialog) => {
    setOpenModalId(paymentMethodId);
    setModalType(modalType);
  };

  const handleModalClose = () => {
    setOpenModalId('');
    setModalType(Dialog.None);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="payment methods table">
        <TableHead>
          <TableRow sx={{ '& th': { fontWeight: 600 } }}>
            <TableCell align="center">Created At (UTC)</TableCell>
            <TableCell align="center">Payment Method ID</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Sender</TableCell>
            <TableCell align="center">External User ID</TableCell>
            <TableCell align="center">Details</TableCell>
            <TableCell align="center">Autopay Consent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((i) => (
            <Fragment key={i.payment_method.payment_method_id}>
              <SenderDetailsModal
                title="Payment Method Sender"
                open={openModalId === i.payment_method.payment_method_id && modalType === Dialog.Sender}
                handleClose={() => handleModalClose()}
                sender={{
                  name: i.payment_user.name,
                  fvUserId: i.payment_user.payment_user_id,
                  externalUserId: i.payment_user.external_user_id,
                  senderType: i.payment_user.payment_user_type,
                  // no sender institution
                }}
              />
              {i.payment_method.payment_method_type === 'MANDATE' && (
                <MandateDetails
                  open={openModalId === i.payment_method.payment_method_id && modalType === Dialog.Details}
                  handleClose={() => handleModalClose()}
                  title="Mandate Details"
                  details={{
                    currency: i.payment_method.mandate.mandate_details.currency,
                    bankReference: i.payment_method.mandate.mandate_details.mandate_bank_reference,
                    paymentMethodId: i.payment_method.payment_method_id,
                    mandateId: i.payment_method.mandate.mandate_id,
                    description: i.payment_method.mandate.mandate_details.description,
                    startDate: i.payment_method.mandate.mandate_details.start_date,
                    endDate: i.payment_method.mandate.mandate_details.end_date,
                    maxTotalAmount: getCurrencyView(
                      i.payment_method.mandate.mandate_details.transaction_limits?.max_period_amount,
                      i.payment_method.mandate.mandate_details.currency,
                    ),
                    maxTxnAmount: getCurrencyView(
                      i.payment_method.mandate.mandate_details.transaction_limits?.max_transaction_amount,
                      i.payment_method.mandate.mandate_details.currency,
                    ),
                    maxTxnCount: i.payment_method.mandate.mandate_details.transaction_limits?.max_period_count,
                    period: i.payment_method.mandate.mandate_details.transaction_limits?.period ?? '',
                    recipientName: i.payment_method.mandate.recipient.name,
                    recipientAccountId: i.payment_method.mandate.recipient.recipient_account_id,
                    customerAppId: i.payment_user.customer_app_id,
                  }}
                />
              )}
              {i.payment_method.payment_method_type === 'CARD' && (
                <CardDetails
                  open={openModalId === i.payment_method.payment_method_id && modalType === Dialog.Details}
                  handleClose={() => handleModalClose()}
                  title="Card Details"
                  details={{
                    cardBrand: i.payment_method.card.card_details?.brand ?? '',
                    cardLast4: i.payment_method.card.card_details?.last4 ?? '',
                    cardExpiryMonth: i.payment_method.card.card_details?.expiry_month?.toString(),
                    cardExpiryYear: i.payment_method.card.card_details?.expiry_year?.toString(),
                    paymentMethodId: i.payment_method.payment_method_id,
                    customerAppId: i.payment_user.customer_app_id,
                    recipientName: i.payment_method.card.recipient?.name,
                    recipientAccountId: i.payment_method.card.recipient_account?.account_id,
                  }}
                />
              )}
              {getPaymentMethodStatus(i.payment_method) === 'FAILED' && (
                <ErrorDetails
                  open={i.payment_method.payment_method_id === openModalId && modalType === Dialog.Error}
                  handleClose={handleModalClose}
                  error={getPaymentMethodError(i.payment_method)}
                />
              )}
              <TableRow>
                <TableCell align="center">{i.payment_method.created_at}</TableCell>
                <TableCell align="center">{i.payment_method.payment_method_id}</TableCell>
                <TableCell align="center">
                  {getPaymentMethodStatus(i.payment_method) !== 'FAILED' ? (
                    getPaymentMethodStatus(i.payment_method)
                  ) : (
                    <Button
                      size="large"
                      sx={{ padding: 0 }}
                      onClick={() => {
                        setModalType(Dialog.Error);
                        setOpenModalId(i.payment_method.payment_method_id);
                      }}
                    >
                      {getPaymentMethodStatus(i.payment_method)}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center">{i.payment_method.payment_method_type}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => openModal(i.payment_method.payment_method_id, Dialog.Sender)}>
                    {i.payment_user.name}
                  </Button>
                </TableCell>
                <TableCell align="center">{i.payment_user.external_user_id}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => openModal(i.payment_method.payment_method_id, Dialog.Details)}>Details</Button>
                </TableCell>
                <TableCell align="center">{i.payment_user.autopay_consent ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
