import { DialogContent, Table, TableBody } from '@mui/material';

import { EmbeddedFvError } from '../../../lib/interface';
import RowIfNotEmpty from './Row';
import DialogWrapper from './Wrapper';

type ErrorDetailsProps = {
  open: boolean;
  handleClose: () => void;
  error?: EmbeddedFvError;
};

export function ErrorDetails({ open, handleClose, error }: ErrorDetailsProps) {
  return (
    <DialogWrapper id="" onClose={handleClose} title="Error" open={open}>
      <DialogContent dividers>
        <Table>
          <TableBody>
            {RowIfNotEmpty('Type', error?.type)}
            {RowIfNotEmpty('Code', error?.error_code)}
            {RowIfNotEmpty('Message', error?.message)}
            {RowIfNotEmpty('Details', error?.details)}
          </TableBody>
        </Table>
      </DialogContent>
    </DialogWrapper>
  );
}
